.custom-form {
  border-color: var(--light-bg-color);
  border-radius: 0.25rem;
}
.custom-form:focus {
  border: 1px solid var(--light-bg-color);
  box-shadow: 0 0 0 0.2rem var(--minus20-main-bg-color);
}
.custom-form:disabled {
  border: 1px solid var(--light-bg-color);
  background-color: var(--light-bg-color);
  color: var(--dark-text);
}

.custom-form-select {
  background-color: var(--light-bg-color);
}
.custom-form-select:checked,
.custom-form-select:hover,
.custom-form-select:active,
.custom-form-select:focus {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}

.color-square {
  width: 35px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid var(--main-bg-color);
}
.color-square-large {
  width: 75px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid var(--main-bg-color);
}
.color-strip {
  margin: 5px;
  width: 250px;
  height: 15px;
  border: 1px solid var(--main-bg-color);
  border-radius: 5px;
}
.color-strip-mini {
  width: 100px;
  height: 15px;
  border: 1px solid var(--main-bg-color);
  border-radius: 5px;
  margin: 5px;
}

.non-form-input {
  margin-top: 5px;
  margin-bottom: 5px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:disabled + .slider {
  background-color: var(--minus20-secondary-bg-color);
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--dark-bg-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: var(--main-bg-color);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--main-bg-color);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.excel-export {
  height: 100%;
  text-align: center;
  padding-top: 18px;
}

.faq-tag {
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.cardview {
  border-radius: 5px;
  margin-bottom: 5px;
  margin: -5px;
}
.cardview-card {
  margin: 5px;
  padding: 5px;
  padding: 5px;
  padding: 5px;
  background-color: var(--light-bg-color);
  border-radius: 5px;
  border: 1px solid var(--minus20-main-bg-color);
  box-shadow: 2px 2px 2px var(--minus10-main-bg-color);
}
.cardview-card:hover {
  background-color: var(--minus10-light-bg-color);
}
.cardview-header {
  background-color: var(--main-bg-color);
  color: var(--white-text);
  text-align: left;
  padding: 2px;
  padding-left: 10px;
  font-weight: bold;
  border-radius: 5px;
}
.cardview-text {
  font-size: 1em;
}
.cardview-text-small {
  font-size: 0.8em;
}
.card-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}
.card-text-padding {
  padding: 3px;
}
.card-separator {
  margin: 10px;
}
.cardview-pop {
  border-radius: 5px;
  box-shadow: 0px 0px 6px var(--minus20-main-bg-color);
  padding: 3px;
  margin-bottom: 5px;
}

.mini-header {
  background-color: var(--main-bg-color);
  color: var(--white-text);
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.form-divided-section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid var(--main-bg-color);
  border-right: 1px solid var(--main-bg-color);
  border-bottom: 1px solid var(--main-bg-color);
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  background-color: var(--light-bg-color);
  color: var(--dark-text);
}
.form-divided-section.fill {
  padding: 5px;
}

.bg-light-m10 {
  background-color: var(--minus10-light-bg-color);
}

.button-group-box {
  background-color: var(--light-bg-color);
  padding: 10px;
  border: 2px solid var(--main-bg-color);
  border-radius: 10px;
}

.header-p {
  padding-top: 10px;
}

.nfs {
  /* background-color: var(--light-bg-color); */
  padding: 5px;
  border-radius: 15px;
  padding-top: 15px;
  padding-left: 0px;
}
.nfs-label {
  color: var(--main-bg-color);
  margin-right: 10px;
  font-size: 1.2em;
}
.nfs-select {
  margin: 5px;
  font-size: 1.2em;
  background-color: var(--light-bg-color);
  color: var(--dark-text);
  padding: 2px;
  border: 2px solid var(--main-bg-color);
  border-radius: 10px;
  max-width: 100%;
}

.thead-custom {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
.tbody-custom {
  background-color: var(--light-bg-color);
  color: var(--dark-text);
}
.trow-alt {
  background-color: rgba(0, 0, 0, 0.05);
}
.trow-highlight:hover {
  background-color: var(--minus10-light-bg-color);
}
th {
  border-top: none !important;
}
th:first-of-type {
  border-top-left-radius: 5px;
}
th:last-of-type {
  border-top-right-radius: 5px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 5px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 5px;
}

.Toastify__toast {
  border-radius: 10px;
  margin: 15px;
  border: 1px solid var(--main-bg-color);
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 5px;
  border-right-width: 5px;
  padding: 10px;
  width: calc(100% - 30px);
}

.Toastify__close-button {
  color: var(--light-bg-color);
}
.Toastify__close-button--info {
  color: var(--main-bg-color);
}

.Toastify__toast--success {
  background-color: var(--main-bg-color);
  color: var(--white-text);
  border-color: var(--dark-bg-color);
}
.Toastify__progress-bar--success {
  background-color: var(--dark-bg-color);
}
.Toastify__toast--error {
  background-color: var(--dark-bg-color);
  color: var(--white-text);
}
.Toastify__progress-bar--error {
  background-color: var(--main-bg-color);
}
.Toastify__toast--info {
  background-color: var(--light-bg-color);
  color: var(--main-bg-color);
}
.Toastify__progress-bar--info {
  background-color: var(--main-bg-color);
}
.Toastify__toast--warning {
  background-color: #ffff00;
  color: var(--main-bg-color);
}
.Toastify__progress-bar--warning {
  background-color: var(--main-bg-color);
}
.Toastify__toast-theme--dark {
  background-color: #ff0000;
  color: var(--white-text);
}
.Toastify__progress-bar-theme--dark {
  background-color: var(--white-text);
}

.usb-logo-ul li::marker {
  color: var(--main-bg-color);
  font-size: 15px;
}

.scoreboard-add {
  border: 2px dashed var(--main-bg-color);
  border-radius: 5px;
  width: 100px;
  height: 100px;
  color: var(--main-bg-color);
  font-size: 50px;
  margin: 5px;
}
.scoreboard-add:hover {
  opacity: 0.5;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.fade-in-out {
  animation: fadeInOut 2s ease-in-out;
  animation-iteration-count: infinite;
}

.box-score-table {
  border: 1px solid var(--main-bg-color);
  margin: 10px;
}
.box-score-header {
  padding: 5px;
}
.box-score-body {
  padding: 5px;
}
.box-score-inning {
  width: 30px;
  text-align: center;
}
.box-score-inning.total {
  color: var(--main-bg-color);
}

.scrolling-table-container {
  overflow-x: auto;
}
.tennis-score-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5em;
}
.tennis-score-team {
  font-weight: bold;
  padding-left: 15px;
  padding-right: 25px;
}
.tennis-border {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.tennis-score-set {
  text-align: center;
}
.tennis-score-set.points {
  display: block;
  width: 35px;
  padding: 5px;
  color: var(--main-bg-color);
  font-weight: bold;
}
.tennis-notice {
  height: 45px;
  font-weight: bold;
  color: var(--main-bg-color);
  text-align: center;
}
.scorecard-info {
  color: var(--main-bg-color);
  font-weight: bold;
}

.map-location-pointer {
  width: 35px;
  height: 35px;
  background-color: var(--light-bg-color);
  border-radius: 25px;
  border-top-left-radius: 0px;
  border: 2px solid var(--main-bg-color);
}
.map-location-name {
  color: var(--white-text);
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5em;
  background-color: var(--main-bg-color);
  border: 2px solid var(--dark-bg-color);
  padding: 2px;
  border-radius: 5px;
}

@keyframes example {
  0% {
    background-color: var(--light-bg-color);
  }
  50% {
    background-color: var(--minus20-main-bg-color);
  }
  100% {
    background-color: var(--light-bg-color);
  }
}

.loading-placeholder-background {
  height: 150px;
  border-radius: 5px;
  background-color: inherit;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
}

.loading-placeholder-text {
  margin: 10px;
  border-radius: 25px;
  background-color: var(--main-bg-color);
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loading-line {
  width: 25%;
  height: 15px;
  border-radius: 5px;
  margin: 10px;
  background-color: var(--minus10-light-bg-color);
  box-shadow: 3px 3px 5px var(--minus20-main-bg-color);
  animation: growShrink 3.5s infinite ease-in-out;
}
.loading-line:nth-child(2) {
  animation-delay: 0.25s;
}
.loading-line:nth-child(3) {
  animation-delay: 0.5s;
}
@keyframes growShrink {
  0%,
  100% {
    width: 15%;
  }
  50% {
    width: 85%;
  }
}

.modal-picker-button {
  background-color: var(--secondary-bg-color);
  padding: 3px;
  cursor: pointer;
  color: var(--white-text);
  border-radius: 5px;
  text-align: center;
  margin: 5px;
}
.modal-picker-button:hover {
  background-color: var(--minus10-secondary-bg-color);
}

.modal-picker-header {
  background-color: var(--secondary-bg-color);
  color: var(--white-text);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
}
.modal-picker-item {
  border: 1px solid var(--main-bg-color);
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
}
.modal-picker-item:hover {
  background-color: var(--minus20-main-bg-color);
  color: var(--white-text);
}
.modal-picker-item.selected {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}

.flip-text {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: var(--muted-bg-color);
}
::-webkit-scrollbar-thumb {
  background: var(--main-bg-color);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--minus20-main-bg-color);
}

.light-box {
  box-shadow: 0 0 10px var(--minus10-light-bg-color);
}

.badge-new {
  background-color: var(--main-bg-color);
  color: var(--white-text);
  padding: 2px 4px;
  font-size: 0.75em;
  border-radius: 3px;
}

.step-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 10px;
  position: relative;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  position: relative;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--minus10-light-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.step.active {
  background-color: var(--main-bg-color);
}
.step.complete {
  background-color: var(--minus10-main-bg-color);
}

.step-number {
  color: var(--white-text);
  font-weight: bold;
}

.step-label {
  margin-top: 10px;
  font-size: 12px;
  max-width: 80px;
  text-align: center;
}
.step-label.active {
  font-weight: bold;
}

.step-line {
  position: absolute;
  top: 15px;
  left: 50%;
  width: calc(100% - 15px);
  height: 2px;
  background-color: var(--minus10-light-bg-color);
  z-index: 0;
}
.step-line.active {
  background-color: var(--main-bg-color);
}

.profile-status {
  margin: 5px;
}

.custom-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.custom-pagination-item {
  border-radius: 5px;
  border-right: 1px solid var(--main-bg-color);
  border-bottom: 1px solid var(--main-bg-color);
  min-width: 40px;
  height: 40px;
  background-color: var(--light-bg-color);
  color: var(--main-bg-color);
  box-shadow: 2px 2px 2px var(--minus10-main-bg-color);
  text-align: center;
  align-content: center;
  font-size: 1.1em;
}
.custom-pagination-item:hover,
.custom-pagination-item:active {
  background-color: var(--minus10-light-bg-color);
  font-weight: bold;
}
.custom-pagination-item.selected {
  background-color: var(--main-bg-color);
  color: var(--white-text);
  font-weight: bold;
  border-right: 1px solid var(--light-bg-color);
  border-bottom: 1px solid var(--light-bg-color);
}
.custom-pagination-item.disabled {
  color: var(--minus10-light-bg-color);
}
.custom-pagination-item.disabled:hover {
  background-color: var(--light-bg-color);
}
